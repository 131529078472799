import { DEBUG } from "@messenger/config";

export const CLIPBOARD_ITEM_SUPPORTED =
  window.navigator.clipboard && window.ClipboardItem;

const textCopyEl = document.createElement("textarea");
textCopyEl.setAttribute("readonly", "");
textCopyEl.tabIndex = -1;
textCopyEl.className = "visually-hidden";

const fallback = (text: string) => {
  const isIos = navigator.userAgent.match(/ipad|iphone/i);
  const textarea = document.createElement("textarea");

  // create textarea
  textarea.value = text;

  // ios will zoom in on the input if the font-size is < 16px
  textarea.style.display = "none";
  document.body.appendChild(textarea);

  // select text
  if (isIos) {
    const range = document.createRange();
    range.selectNodeContents(textarea);

    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
    textarea.setSelectionRange(0, 999999);
  } else {
    textarea.select();
  }

  // copy selection
  document.execCommand("copy");

  // cleanup
  document.body.removeChild(textarea);
};

export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallback(text);
    return;
  }

  navigator.clipboard.writeText(text);
}

export const copyImageToClipboard = (imageUrl?: string) => {
  if (!imageUrl) return;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const imageEl = new Image();
  imageEl.onload = (e: Event) => {
    if (ctx && e.currentTarget) {
      const img = e.currentTarget as HTMLImageElement;
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);
      canvas.toBlob(copyBlobToClipboard, "image/png", 1);
    }
  };

  imageEl.src = imageUrl;
};

async function copyBlobToClipboard(pngBlob: Blob | null) {
  if (!pngBlob || !CLIPBOARD_ITEM_SUPPORTED) {
    return;
  }

  try {
    await window.navigator.clipboard.write?.([
      new ClipboardItem({
        [pngBlob.type]: pngBlob,
      }),
    ]);
  } catch (error) {
    if (DEBUG) {
      console.error(error);
    }
  }
}
