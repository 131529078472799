import type { ChangeEvent } from "react";

import monkeyPath from "../../assets/monkey.svg";

// biome-ignore lint/style/useImportType: <explanation>
import React from "@messenger/lib/teact/teact";
import {
  type FC,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "@messenger/lib/teact/teact";
import { getActions, withGlobal } from "@messenger/global";

import type { GlobalState } from "@messenger/global/types";
import type { LangCode } from "@messenger/types";
import type { ApiCountryCode } from "@messenger/api/types";

import { IS_SAFARI, IS_TOUCH_ENV } from "@messenger/util/environment";
import { preloadImage } from "@messenger/util/files";
import preloadFonts from "@messenger/util/fonts";
import { pick } from "@messenger/util/iteratees";
import {
  formatPhoneNumber,
  getCountryCodesByIso,
  getCountryFromPhoneNumber,
} from "@messenger/util/phoneNumber";
import { setLanguage } from "@messenger/util/langProvider";
import useLang from "@messenger/hooks/useLang";
import useFlag from "@messenger/hooks/useFlag";
import useLangString from "@messenger/hooks/useLangString";
import { getSuggestedLanguage } from "./helpers/getSuggestedLanguage";

import Button from "../ui/Button";
import Checkbox from "../ui/Checkbox";
import InputText from "../ui/InputText";
import Loading from "../ui/Loading";
import CountryCodeInput from "./CountryCodeInput";

import AuthBrand from "@messenger/components/auth/AuthBrand";
import * as styles from "@messenger/components/auth/AuthQrCode.module.scss";
import * as authCommonStyles from "@messenger/components/auth/styles.module.scss";
import { cn } from "@messenger/shared/styles";

type StateProps = Pick<
  GlobalState,
  | "connectionState"
  | "authState"
  | "authPhoneNumber"
  | "authIsLoading"
  | "authIsLoadingQrCode"
  | "authError"
  | "authRememberMe"
  | "authNearestCountry"
> & {
  language?: LangCode;
  phoneCodeList: ApiCountryCode[];
};

const MIN_NUMBER_LENGTH = 7;

let isPreloadInitiated = false;

const AuthPhoneNumber: FC<StateProps> = ({
  connectionState,
  authState,
  authPhoneNumber,
  authIsLoading,
  authIsLoadingQrCode,
  authError,
  authRememberMe,
  authNearestCountry,
  phoneCodeList,
  language,
}) => {
  const {
    setAuthPhoneNumber,
    setAuthRememberMe,
    loadNearestCountry,
    loadCountryList,
    clearAuthError,
    goToAuthQrCode,
    setSettingOption,
  } = getActions();

  const lang = useLang();

  const inputRef = useRef<HTMLInputElement>(null);
  const suggestedLanguage = getSuggestedLanguage();

  const continueText = useLangString(
    suggestedLanguage,
    "ContinueOnThisLanguage",
  );
  const [country, setCountry] = useState<ApiCountryCode | undefined>();
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [isTouched, setIsTouched] = useState(false);
  const [lastSelection, setLastSelection] = useState<
    [number, number] | undefined
  >();
  const [isLoading, markIsLoading, unmarkIsLoading] = useFlag();

  const fullNumber = country
    ? `+${country.countryCode} ${phoneNumber || ""}`
    : phoneNumber;
  const canSubmit =
    fullNumber && fullNumber.replace(/[^\d]+/g, "").length >= MIN_NUMBER_LENGTH;

  useEffect(() => {
    if (!IS_TOUCH_ENV) {
      inputRef.current?.focus();
    }
  }, [country]);

  useEffect(() => {
    if (connectionState === "connectionStateReady" && !authNearestCountry) {
      loadNearestCountry();
    }
  }, [connectionState, authNearestCountry, loadNearestCountry]);

  useEffect(() => {
    if (connectionState === "connectionStateReady") {
      loadCountryList({ langCode: language });
    }
  }, [connectionState, language, loadCountryList]);

  useEffect(() => {
    if (authNearestCountry && phoneCodeList && !country && !isTouched) {
      setCountry(getCountryCodesByIso(phoneCodeList, authNearestCountry)[0]);
    }
  }, [country, authNearestCountry, isTouched, phoneCodeList]);

  const parseFullNumber = useCallback(
    (newFullNumber: string) => {
      if (!newFullNumber.length) {
        setPhoneNumber("");
      }

      const suggestedCountry =
        phoneCodeList &&
        getCountryFromPhoneNumber(phoneCodeList, newFullNumber);

      // Any phone numbers should be allowed, in some cases ignoring formatting
      const selectedCountry =
        !country ||
        (suggestedCountry && suggestedCountry.iso2 !== country.iso2) ||
        (!suggestedCountry && newFullNumber.length)
          ? suggestedCountry
          : country;

      if (
        !country ||
        !selectedCountry ||
        (selectedCountry && selectedCountry.iso2 !== country.iso2)
      ) {
        setCountry(selectedCountry);
      }
      setPhoneNumber(formatPhoneNumber(newFullNumber, selectedCountry));
    },
    [phoneCodeList, country],
  );

  const handleLangChange = useCallback(() => {
    markIsLoading();

    void setLanguage(suggestedLanguage, () => {
      unmarkIsLoading();

      setSettingOption({ language: suggestedLanguage });
    });
  }, [markIsLoading, setSettingOption, suggestedLanguage, unmarkIsLoading]);

  useEffect(() => {
    if (phoneNumber === undefined && authPhoneNumber) {
      parseFullNumber(authPhoneNumber);
    }
  }, [authPhoneNumber, phoneNumber, parseFullNumber]);

  useLayoutEffect(() => {
    if (inputRef.current && lastSelection) {
      inputRef.current.setSelectionRange(...lastSelection);
    }
  }, [lastSelection]);

  const isJustPastedRef = useRef(false);

  const handlePaste = useCallback(() => {
    isJustPastedRef.current = true;

    requestAnimationFrame(() => {
      isJustPastedRef.current = false;
    });
  }, []);

  const handleCountryChange = useCallback((value: ApiCountryCode) => {
    setCountry(value);
    setPhoneNumber("");
  }, []);

  const handlePhoneNumberChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (authError) {
        clearAuthError();
      }

      // This is for further screens. We delay it until user input to speed up the initial loading.
      if (!isPreloadInitiated) {
        isPreloadInitiated = true;
        preloadFonts();
        void preloadImage(monkeyPath);
      }

      const { value, selectionStart, selectionEnd } = e.target;
      setLastSelection(
        selectionStart && selectionEnd && selectionEnd < value.length
          ? [selectionStart, selectionEnd]
          : undefined,
      );

      setIsTouched(true);

      const shouldFixSafariAutoComplete =
        IS_SAFARI &&
        country &&
        fullNumber !== undefined &&
        value.length - fullNumber.length > 1 &&
        !isJustPastedRef.current;
      parseFullNumber(
        shouldFixSafariAutoComplete
          ? `${country?.countryCode} ${value}`
          : value,
      );
    },
    [authError, clearAuthError, country, fullNumber, parseFullNumber],
  );

  const handleKeepSessionChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setAuthRememberMe(e.target.checked);
    },
    [setAuthRememberMe],
  );

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (authIsLoading) {
      return;
    }

    if (canSubmit) {
      setAuthPhoneNumber({ phoneNumber: fullNumber });
    }
  }

  const isAuthReady = authState === "authorizationStateWaitPhoneNumber";

  return (
    <div className={authCommonStyles.page}>
      <AuthBrand />

      <section className={authCommonStyles.halfPageWrapper}>
        <div
          className={cn(authCommonStyles.halfPageContainer, styles.container)}
        >
          <div
            id="auth-phone-number-form"
            className={cn("custom-scroll", styles.form)}
          >
            <div className="auth-form">
              {/* <div id="logo" /> */}
              {/* <h2>Tawasal</h2> */}
              {/* <p className="note">{lang('StartText')}</p> */}
              <form action="" onSubmit={handleSubmit}>
                {/* <CountryCodeInput */}
                {/*   id="sign-in-phone-code" */}
                {/*   value={country} */}
                {/*   isLoading={!authNearestCountry && !country} */}
                {/*   onChange={handleCountryChange} */}
                {/* /> */}
                <InputText
                  ref={inputRef}
                  id="sign-in-phone-number"
                  label={lang("Login.PhonePlaceholder")}
                  value={fullNumber}
                  error={authError && lang(authError)}
                  inputMode="tel"
                  onChange={handlePhoneNumberChange}
                  onPaste={IS_SAFARI ? handlePaste : undefined}
                />
                <Checkbox
                  id="sign-in-keep-session"
                  label="Keep me signed in"
                  checked={Boolean(authRememberMe)}
                  onChange={handleKeepSessionChange}
                />
                {canSubmit &&
                  (isAuthReady ? (
                    <Button type="submit" ripple isLoading={authIsLoading}>
                      {lang("Login.Next")}
                    </Button>
                  ) : (
                    <Loading />
                  ))}
                {isAuthReady && (
                  <Button
                    isText
                    ripple
                    isLoading={authIsLoadingQrCode}
                    onClick={goToAuthQrCode}
                  >
                    {lang("Login.QR.Login")}
                  </Button>
                )}
                {/* {suggestedLanguage && suggestedLanguage !== language && continueText && ( */}
                {/*   <Button isText isLoading={isLoading} onClick={handleLangChange}> */}
                {/*     {continueText} */}
                {/*   </Button> */}
                {/* )} */}
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default memo(
  withGlobal((global): StateProps => {
    const {
      settings: {
        byKey: { language },
      },
      countryList: { phoneCodes: phoneCodeList },
    } = global;

    return {
      ...pick(global, [
        "connectionState",
        "authState",
        "authPhoneNumber",
        "authIsLoading",
        "authIsLoadingQrCode",
        "authError",
        "authRememberMe",
        "authNearestCountry",
      ]),
      language,
      phoneCodeList,
    };
  })(AuthPhoneNumber),
);
