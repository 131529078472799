import React, { type FC, memo } from "@messenger/lib/teact/teact";
import { Bundles } from "@messenger/util/moduleLoader";
import Loading from "@messenger/components/ui/Loading";
import useModuleLoader from "@messenger/hooks/useModuleLoader";

const AuthCodeAsync: FC = () => {
  const AuthCode = useModuleLoader(Bundles.Auth, "AuthCode");

  return AuthCode ? <AuthCode /> : <Loading />;
};

export default memo(AuthCodeAsync);
