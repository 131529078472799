import { GroupCallParticipant as TypeGroupCallParticipant } from "@messenger/lib/secret-sauce";
import React, { FC, memo, useMemo } from "@messenger/lib/teact/teact";
import { getActions, withGlobal } from "@messenger/global";

import useLang from "@messenger/hooks/useLang";
import { selectActiveGroupCall } from "@messenger/global/selectors/calls";
import useInfiniteScroll from "@messenger/hooks/useInfiniteScroll";

import GroupCallParticipant from "@messenger/components/calls/group/GroupCallParticipant";
import InfiniteScroll from "@messenger/components/ui/InfiniteScroll";

type OwnProps = {
  openParticipantMenu: (
    anchor: HTMLDivElement,
    participant: TypeGroupCallParticipant,
  ) => void;
};

type StateProps = {
  participantsCount: number;
  participants?: Record<string, TypeGroupCallParticipant>;
  canInvite?: boolean;
};

const GroupCallParticipantList: FC<OwnProps & StateProps> = ({
  participants,
  participantsCount,
  openParticipantMenu,
}) => {
  const { createGroupCallInviteLink, loadMoreGroupCallParticipants } =
    getActions();

  const lang = useLang();

  const participantsIds = useMemo(() => {
    return Object.keys(participants || {});
  }, [participants]);

  const [viewportIds, getMore] = useInfiniteScroll(
    loadMoreGroupCallParticipants,
    participantsIds,
    participantsIds.length >= participantsCount,
  );

  return (
    <div className="participants">
      <div className="invite-btn" onClick={createGroupCallInviteLink}>
        <div className="icon">
          <i className="icon-add-user" />
        </div>
        <div className="text">{lang("VoipGroupInviteMember")}</div>
      </div>

      <InfiniteScroll items={viewportIds} onLoadMore={getMore}>
        {viewportIds?.map(
          (participantId) =>
            participants![participantId] && (
              <GroupCallParticipant
                key={participantId}
                openParticipantMenu={openParticipantMenu}
                participant={participants![participantId]}
              />
            ),
        )}
      </InfiniteScroll>
    </div>
  );
};

export default memo(
  withGlobal<OwnProps>((global): StateProps => {
    const { participantsCount, participants } =
      selectActiveGroupCall(global) || {};

    return {
      participants,
      participantsCount: participantsCount || 0,
    };
  })(GroupCallParticipantList),
);
