import * as Sentry from '@sentry/react';

class SentryManager {
  private static _instance: Sentry.BrowserClient;

  private constructor() {} // constructor is closed to prevent external initialization

  public static init() {
    SentryManager.instance;
  }

  public static get instance(): Sentry.BrowserClient {
    if (!SentryManager._instance) {
      Sentry.init({
        dsn: 'https://227e17029d85f73569d292ed1ff3d65b@api.sentry.tawasal.ae/39',
        environment: process.env.APP_ENV,
        enabled: process.env.WEBPACK_MODE === 'production' && process.env.APP_ENV === 'production',
        // debug: true,
        normalizeDepth: 20,
        attachStacktrace: true,
        sendDefaultPii: true,
        enableTracing: true,
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 1.0,
        profilesSampleRate: 1.0,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.browserProfilingIntegration(),
          Sentry.replayIntegration({
            maskAllText: true,
            // blockAllMedia: true,
          }),
          Sentry.replayCanvasIntegration(),
          Sentry.captureConsoleIntegration(),
          // Sentry.contextLinesIntegration(),
          Sentry.extraErrorDataIntegration(),
          Sentry.httpClientIntegration(),
          // Sentry.moduleMetadataIntegration(),
          Sentry.reportingObserverIntegration(),
          Sentry.sessionTimingIntegration(),
        ],
      });

      SentryManager._instance = Sentry.getClient() as Sentry.BrowserClient;

      if (!SentryManager._instance) {
        throw new Error('Sentry client is not initialized properly.');
      }
    }
    return SentryManager._instance;
  }

  public static get clientOptions() {
    return SentryManager.instance.getOptions();
  }

  public static get globalScope() {
    return Sentry.getGlobalScope();
  }

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  public static updateGlobals(newGlobals: any) {
    SentryManager.globalScope.setContext('globals', newGlobals);
  }

  public static setUser(user: Sentry.User) {
    SentryManager.globalScope.setUser({
      id: user.id,
      username: user?.username ?? undefined,
    });

    SentryManager.globalScope.setContext('user', user);

    SentryManager.globalScope.setTags({
      userId: user.id,
      username: user?.username ?? undefined,
    });
  }
}

export { SentryManager };
