import React, { FC } from "@messenger/lib/teact/teact";

import buildClassName from "@messenger/util/buildClassName";

import "@messenger/components/ui/Spinner.scss";

const Spinner: FC<{
  color?: "blue" | "white" | "black" | "green" | "gray" | "yellow";
  backgroundColor?: "light" | "dark";
}> = ({ color = "blue", backgroundColor }) => {
  return (
    <div
      className={buildClassName(
        "Spinner",
        color,
        backgroundColor && "with-background",
        `bg-${backgroundColor}`,
      )}
    >
      <div />
    </div>
  );
};

export default Spinner;
