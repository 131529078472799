import {
  addActionHandler,
  getGlobal,
  setGlobal,
} from "@messenger/global/index";
import {
  joinGroupCall,
  startSharingScreen,
  leaveGroupCall,
  toggleStream,
  isStreamEnabled,
  setVolume,
  handleUpdateGroupCallParticipants,
  handleUpdateGroupCallConnection,
  stopPhoneCall,
} from "@messenger/lib/secret-sauce";

import { GROUP_CALL_VOLUME_MULTIPLIER } from "@messenger/config";
import { callApi } from "@messenger/api/gramjs";
import {
  selectChat,
  selectCurrentMessageList,
  selectUser,
} from "@messenger/global/selectors";
import {
  selectActiveGroupCall,
  selectCallFallbackChannelTitle,
  selectGroupCallParticipant,
  selectPhoneCallUser,
} from "@messenger/global/selectors/calls";
import {
  removeGroupCall,
  updateActiveGroupCall,
  updateGroupCall,
  updateGroupCallParticipant,
} from "@messenger/global/reducers/calls";
import { omit } from "@messenger/util/iteratees";
import { getServerTime } from "@messenger/util/serverTime";
import { fetchFile } from "@messenger/util/files";
import {
  getGroupCallAudioContext,
  getGroupCallAudioElement,
  removeGroupCallAudioElement,
} from "@messenger/global/actions/ui/calls";
import { loadFullChat } from "@messenger/global/actions/api/chats";

import callFallbackAvatarPath from "@messenger/assets/call-fallback-avatar.png";

const FALLBACK_INVITE_EXPIRE_SECONDS = 1800; // 30 min

addActionHandler("apiUpdate", (global, actions, update) => {
  const { activeGroupCallId } = global.groupCalls;

  switch (update["@type"]) {
    case "updateGroupCallLeavePresentation": {
      actions.toggleGroupCallPresentation({ value: false });
      break;
    }
    case "updateGroupCallStreams": {
      if (!update.userId || !activeGroupCallId) break;
      if (!selectGroupCallParticipant(global, activeGroupCallId, update.userId))
        break;

      return updateGroupCallParticipant(
        global,
        activeGroupCallId,
        update.userId,
        omit(update, ["@type", "userId"]),
      );
    }
    case "updateGroupCallConnectionState": {
      if (!activeGroupCallId) break;

      if (update.connectionState === "disconnected") {
        actions.leaveGroupCall({ isFromLibrary: true });
        break;
      }

      return updateGroupCall(global, activeGroupCallId, {
        connectionState: update.connectionState,
        isSpeakerDisabled: update.isSpeakerDisabled,
      });
    }
    case "updateGroupCallParticipants": {
      const { groupCallId, participants } = update;
      if (activeGroupCallId === groupCallId) {
        void handleUpdateGroupCallParticipants(participants);
      }
      break;
    }
    case "updateGroupCallConnection": {
      if (update.data.stream) {
        actions.showNotification({
          message: "Big live streams are not yet supported",
        });
        actions.leaveGroupCall();
        break;
      }
      void handleUpdateGroupCallConnection(update.data, update.presentation);

      const groupCall = selectActiveGroupCall(global);
      if (
        groupCall?.participants &&
        Object.keys(groupCall.participants).length > 0
      ) {
        void handleUpdateGroupCallParticipants(
          Object.values(groupCall.participants),
        );
      }
      break;
    }
  }

  return undefined;
});

addActionHandler("leaveGroupCall", async (global, actions, payload) => {
  const { isFromLibrary, shouldDiscard, shouldRemove, rejoin } = payload || {};
  const groupCall = selectActiveGroupCall(global);
  if (!groupCall) {
    return;
  }

  setGlobal(
    updateActiveGroupCall(
      global,
      { connectionState: "disconnected" },
      groupCall.participantsCount - 1,
    ),
  );

  await callApi("leaveGroupCall", {
    call: groupCall,
  });

  let shouldResetFallbackState = false;
  if (shouldDiscard) {
    global = getGlobal();

    if (global.groupCalls.fallbackChatId === groupCall.chatId) {
      shouldResetFallbackState = true;

      global.groupCalls.fallbackUserIdsToRemove?.forEach((userId) => {
        actions.deleteChatMember({
          chatId: global.groupCalls.fallbackChatId,
          userId,
        });
      });
    }

    await callApi("discardGroupCall", {
      call: groupCall,
    });
  }

  global = getGlobal();
  if (shouldRemove) {
    global = removeGroupCall(global, groupCall.id);
  }

  removeGroupCallAudioElement();

  setGlobal({
    ...global,
    groupCalls: {
      ...global.groupCalls,
      isGroupCallPanelHidden: true,
      activeGroupCallId: undefined,
      ...(shouldResetFallbackState && {
        fallbackChatId: undefined,
        fallbackUserIdsToRemove: undefined,
      }),
    },
  });

  if (!isFromLibrary) {
    leaveGroupCall();
  }

  if (rejoin) {
    actions.joinGroupCall(rejoin);
  }
});

addActionHandler("toggleGroupCallVideo", async (global) => {
  const groupCall = selectActiveGroupCall(global);
  const user = selectUser(global, global.currentUserId!);
  if (!user || !groupCall) {
    return;
  }

  await toggleStream("video");

  await callApi("editGroupCallParticipant", {
    call: groupCall,
    videoStopped: !isStreamEnabled("video"),
    participant: user,
  });
});

addActionHandler("requestToSpeak", (global, actions, payload) => {
  const { value } = payload || { value: true };
  const groupCall = selectActiveGroupCall(global);
  const user = selectUser(global, global.currentUserId!);
  if (!user || !groupCall) {
    return;
  }

  void callApi("editGroupCallParticipant", {
    call: groupCall,
    raiseHand: value,
    participant: user,
  });
});

addActionHandler(
  "setGroupCallParticipantVolume",
  (global, actions, payload) => {
    const { participantId, volume } = payload!;

    const groupCall = selectActiveGroupCall(global);
    const user = selectUser(global, participantId);
    if (!user || !groupCall) {
      return;
    }

    setVolume(
      participantId,
      Math.floor(volume / GROUP_CALL_VOLUME_MULTIPLIER) / 100,
    );

    void callApi("editGroupCallParticipant", {
      call: groupCall,
      volume: Number(volume),
      participant: user,
    });
  },
);

addActionHandler("toggleGroupCallMute", async (global, actions, payload) => {
  const { participantId, value } = payload || {};
  const groupCall = selectActiveGroupCall(global);
  const user = selectUser(global, participantId || global.currentUserId!);
  if (!user || !groupCall) {
    return;
  }

  const muted = value === undefined ? isStreamEnabled("audio", user.id) : value;

  if (!participantId) {
    await toggleStream("audio");
  } else {
    setVolume(participantId, muted ? 0 : 1);
  }

  await callApi("editGroupCallParticipant", {
    call: groupCall,
    muted,
    participant: user,
  });
});

addActionHandler(
  "toggleGroupCallPresentation",
  async (global, actions, payload) => {
    const groupCall = selectActiveGroupCall(global);
    const user = selectUser(global, global.currentUserId!);
    if (!user || !groupCall) {
      return;
    }

    const value =
      payload?.value !== undefined
        ? payload?.value
        : !isStreamEnabled("presentation");
    if (value) {
      const params = await startSharingScreen();
      if (!params) {
        return;
      }

      await callApi("joinGroupCallPresentation", {
        call: groupCall,
        params,
      });
    } else {
      await toggleStream("presentation", false);
      await callApi("leaveGroupCallPresentation", {
        call: groupCall,
      });
    }

    await callApi("editGroupCallParticipant", {
      call: groupCall,
      presentationPaused: !isStreamEnabled("presentation"),
      participant: user,
    });
  },
);

addActionHandler("connectToActiveGroupCall", async (global, actions) => {
  const groupCall = selectActiveGroupCall(global);
  if (!groupCall) return;

  if (groupCall.connectionState === "discarded") {
    actions.showNotification({ message: "This voice chat is not active" });
    return;
  }

  const audioElement = getGroupCallAudioElement();
  const audioContext = getGroupCallAudioContext();

  if (!audioElement || !audioContext) {
    return;
  }

  const { currentUserId } = global;

  if (!currentUserId) return;

  const params = await joinGroupCall(
    currentUserId,
    audioContext,
    audioElement,
    actions.apiUpdate,
  );

  const result = await callApi("joinGroupCall", {
    call: groupCall,
    params,
    inviteHash: groupCall.inviteHash,
  });

  if (!result) return;

  actions.loadMoreGroupCallParticipants();

  if (groupCall.chatId) {
    const chat = selectChat(getGlobal(), groupCall.chatId);
    if (!chat) return;
    await loadFullChat(chat);
  }
});

addActionHandler("inviteToCallFallback", async (global, actions, payload) => {
  const { chatId } = selectCurrentMessageList(global) || {};
  if (!chatId) {
    return;
  }

  const user = selectUser(global, chatId);
  if (!user) {
    return;
  }

  const { shouldRemove } = payload;

  const fallbackChannelTitle = selectCallFallbackChannelTitle(global);

  let fallbackChannel = Object.values(global.chats.byId).find((channel) => {
    return (
      channel.title === fallbackChannelTitle &&
      channel.isCreator &&
      !channel.isRestricted
    );
  });
  if (!fallbackChannel) {
    fallbackChannel = await callApi("createChannel", {
      title: fallbackChannelTitle,
      users: [user],
    });

    if (!fallbackChannel) {
      return;
    }

    const photo = await fetchFile(callFallbackAvatarPath, "avatar.png");
    void callApi("editChatPhoto", {
      chatId: fallbackChannel.id,
      accessHash: fallbackChannel.accessHash,
      photo,
    });
  } else {
    actions.updateChatMemberBannedRights({
      chatId: fallbackChannel.id,
      userId: chatId,
      bannedRights: {},
    });

    void callApi("addChatMembers", fallbackChannel, [user], true);
  }

  const inviteLink = await callApi("updatePrivateLink", {
    chat: fallbackChannel,
    usageLimit: 1,
    expireDate:
      getServerTime(global.serverTimeOffset) + FALLBACK_INVITE_EXPIRE_SECONDS,
  });
  if (!inviteLink) {
    return;
  }

  if (shouldRemove) {
    global = getGlobal();
    const fallbackUserIdsToRemove =
      global.groupCalls.fallbackUserIdsToRemove || [];
    setGlobal({
      ...global,
      groupCalls: {
        ...global.groupCalls,
        fallbackChatId: fallbackChannel.id,
        fallbackUserIdsToRemove: [...fallbackUserIdsToRemove, chatId],
      },
    });
  }

  actions.sendMessage({ text: `Join a call: ${inviteLink}` });
  actions.openChat({ id: fallbackChannel.id });
  actions.createGroupCall({ chatId: fallbackChannel.id });
  actions.closeCallFallbackConfirm();
});

// Before channel update
addActionHandler("connectToActivePhoneCall", async (global) => {
  const { phoneCall } = global;

  if (!phoneCall) return;

  const user = selectPhoneCallUser(global);

  if (!user) return;

  const dhConfig = await callApi("getDhConfig");

  if (!dhConfig) return;

  await callApi("createPhoneCallState", [true]);

  const gAHash = await callApi("requestPhoneCall", [dhConfig])!;

  await callApi("requestCall", { user, gAHash, isVideo: phoneCall.isVideo });
});

addActionHandler("acceptCall", async (global) => {
  const { phoneCall } = global;

  if (!phoneCall) return;

  const dhConfig = await callApi("getDhConfig");
  if (!dhConfig) return;

  await callApi("createPhoneCallState", [false]);

  const gB = await callApi("acceptPhoneCall", [dhConfig])!;
  callApi("acceptCall", { call: phoneCall, gB });
});

addActionHandler("sendSignalingData", (global, actions, payload) => {
  const { phoneCall } = global;
  if (!phoneCall) {
    return;
  }

  const data = JSON.stringify(payload);

  (async () => {
    const encodedData = await callApi("encodePhoneCallData", [data]);

    if (!encodedData) return;

    callApi("sendSignalingData", { data: encodedData, call: phoneCall });
  })();
});

addActionHandler("closeCallRatingModal", (global) => {
  return {
    ...global,
    ratingPhoneCall: undefined,
  };
});

addActionHandler("setCallRating", (global, actions, payload) => {
  const { ratingPhoneCall } = global;
  if (!ratingPhoneCall) {
    return undefined;
  }

  const { rating, comment } = payload;

  callApi("setCallRating", { call: ratingPhoneCall, rating, comment });

  return {
    ...global,
    ratingPhoneCall: undefined,
  };
});

addActionHandler("hangUp", (global) => {
  const { phoneCall } = global;

  if (!phoneCall) return undefined;

  if (phoneCall.state === "discarded") {
    callApi("destroyPhoneCallState");
    stopPhoneCall();
    return {
      ...global,
      phoneCall: undefined,
      isCallPanelVisible: undefined,
    };
  }

  callApi("destroyPhoneCallState");
  stopPhoneCall();
  callApi("discardCall", { call: phoneCall });

  if (phoneCall.state === "requesting") {
    return {
      ...global,
      phoneCall: undefined,
      isCallPanelVisible: undefined,
    };
  }

  setTimeout(() => {
    setGlobal({
      ...getGlobal(),
      phoneCall: undefined,
      isCallPanelVisible: undefined,
    });
  }, 500);

  return undefined;
});
//--- Before channel update
