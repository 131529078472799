import type { GlobalState } from "@messenger/global/types";

import { selectCurrentMessageList } from "@messenger/global/selectors/messages";
import { selectChatFullInfo } from "@messenger/global/selectors/chats";

export function selectStatistics(global: GlobalState, chatId: string) {
  return global.statistics.byChatId[chatId];
}

export function selectIsStatisticsShown(global: GlobalState) {
  if (!global.isStatisticsShown) {
    return false;
  }

  const { chatId: currentChatId } = selectCurrentMessageList(global) || {};

  return currentChatId
    ? selectChatFullInfo(global, currentChatId)?.canViewStatistics
    : undefined;
}
