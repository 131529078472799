import type { MouseEvent as ReactMouseEvent } from "react";
import React, { type FC, memo, useCallback } from "@messenger/lib/teact/teact";

import {
  type ApiChat,
  ApiMediaFormat,
  type ApiPeer,
  type ApiPhoto,
  type ApiUser,
  type ApiUserStatus,
} from "@messenger/api/types";

import { IS_TEST } from "@messenger/config";
import {
  getChatAvatarHash,
  getChatTitle,
  getUserColorKey,
  getUserFullName,
  isUserId,
  isChatWithRepliesBot,
  isDeletedUser,
  isUserOnline,
} from "@messenger/global/helpers";
import { getFirstLetters } from "@messenger/util/textFormat";
import buildClassName from "@messenger/util/buildClassName";
import renderText from "@messenger/components/common/helpers/renderText";
import useMedia from "@messenger/hooks/useMedia";
import useShowTransition from "@messenger/hooks/useShowTransition";
import useLang from "@messenger/hooks/useLang";

import "@messenger/components/common/Avatar.scss";

type OwnProps = {
  className?: string;
  size?: "micro" | "tiny" | "small" | "medium" | "large" | "jumbo";
  // peer?: ApiPeer;
  chat?: ApiChat;
  user?: ApiUser;
  photo?: ApiPhoto;
  userStatus?: ApiUserStatus;
  text?: string;
  isSavedMessages?: boolean;
  isSavedDialog?: boolean;
  lastSyncTime?: number;
  onClick?: (
    e: ReactMouseEvent<HTMLDivElement, MouseEvent>,
    hasPhoto: boolean,
  ) => void;
};

const Avatar: FC<OwnProps> = ({
  className,
  size = "large",
  chat,
  user,
  photo,
  userStatus,
  text,
  isSavedMessages,
  lastSyncTime,
  onClick,
  isSavedDialog,
  // peer,
}) => {
  const isDeleted = user && isDeletedUser(user);
  const isReplies = user && isChatWithRepliesBot(user.id);
  // const realPeer = peer ? peer : undefined;
  // const isPeerChat = realPeer && 'title' in realPeer;

  let imageHash: string | undefined;

  if (!isSavedMessages && !isDeleted) {
    if (user) {
      imageHash = getChatAvatarHash(user);
    } else if (chat) {
      imageHash = getChatAvatarHash(chat);
    } else if (photo) {
      imageHash = `photo${photo.id}?size=m`;
    }
  }

  const blobUrl = useMedia(
    imageHash,
    false,
    ApiMediaFormat.BlobUrl,
    lastSyncTime,
  );
  const hasBlobUrl = Boolean(blobUrl);
  const { transitionClassNames } = useShowTransition(
    hasBlobUrl,
    undefined,
    hasBlobUrl,
    "slow",
  );

  const lang = useLang();

  let content: string | undefined = "";

  if (isSavedMessages) {
    content = isSavedDialog ? (
      <i className="icon-my-notes" />
    ) : (
      <i className="icon-avatar-saved-messages" />
    );
  } else if (isDeleted) {
    content = <i className="icon-avatar-deleted-account" />;
  } else if (isReplies) {
    content = <i className="icon-reply-filled" />;
  } else if (blobUrl) {
    content = (
      <img
        src={blobUrl}
        className={buildClassName("avatar-media", transitionClassNames)}
        alt=""
        decoding="async"
      />
    );
  } else if (user) {
    const userFullName = getUserFullName(user);
    content = userFullName ? getFirstLetters(userFullName, 2) : undefined;
  } else if (chat) {
    const title = getChatTitle(lang, chat);
    content = title && getFirstLetters(title, isUserId(chat.id) ? 2 : 1);
  } else if (text) {
    content = getFirstLetters(text, 2);
  }

  const isOnline =
    !isSavedMessages && user && userStatus && isUserOnline(user, userStatus);
  const fullClassName = buildClassName(
    `Avatar size-${size}`,
    className,
    `color-bg-${getUserColorKey(user || chat)}`,
    isSavedMessages && "saved-messages",
    isDeleted && "deleted-account",
    isReplies && "replies-bot-account",
    isOnline && "online",
    onClick && "interactive",
    !isSavedMessages && !blobUrl && "no-photo",
  );

  const hasImage = Boolean(isSavedMessages || blobUrl);
  const handleClick = useCallback(
    (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
      if (onClick) {
        onClick(e, hasImage);
      }
    },
    [onClick, hasImage],
  );

  const senderId = (user || chat) && (user || chat)?.id;

  return (
    <div
      className={fullClassName}
      onClick={handleClick}
      data-test-sender-id={IS_TEST ? senderId : undefined}
    >
      {typeof content === "string"
        ? renderText(content, [size === "jumbo" ? "hq_emoji" : "emoji"])
        : content}
    </div>
  );
};

export default memo(Avatar);
