import React, { type FC, memo } from "@messenger/lib/teact/teact";
import { Bundles } from "@messenger/util/moduleLoader";

import useModuleLoader from "@messenger/hooks/useModuleLoader";

const MainAsync: FC = () => {
  const Main = useModuleLoader(Bundles.Main, "Main");

  return Main ? <Main /> : undefined;
};

export default memo(MainAsync);
