import React, { type FC, useEffect, memo } from "@messenger/lib/teact/teact";
import { getActions, withGlobal } from "@messenger/global";

import type { GlobalState } from "@messenger/global/types";

import "@messenger/global/actions/initial";
import "@messenger/global/actions/api/business";
import { pick } from "@messenger/util/iteratees";
import windowSize from "@messenger/util/windowSize";

type OwnProps = {
  isActive: boolean;
};

type StateProps = Pick<
  GlobalState,
  "businessAuthState" | "connectionState" | "authState" | "companyAuthState"
>;

const BusinessAuth: FC<OwnProps & StateProps> = ({
  businessAuthState,
  authState,
  connectionState,
  isActive,
  companyAuthState,
}) => {
  const { initBusiness } = getActions();

  const companyInvite = new URLSearchParams(window.location.search).get(
    "companyInvite",
  );
  const password = new URLSearchParams(window.location.search).get("password");
  const email = new URLSearchParams(window.location.search).get("email");

  const { initApi } = getActions();

  useEffect(() => {
    initApi();
  }, [initApi]);

  useEffect(() => {
    if (connectionState === "connectionStateReady") {
      initBusiness({ companyInvite, password, email });
    }
  }, [initBusiness, connectionState]);

  useEffect(() => {
    windowSize.disableRefresh();

    return () => {
      windowSize.enableRefresh();
    };
  }, []);

  return null;
};

export default memo(
  withGlobal<OwnProps>(
    (global): StateProps =>
      pick(global, [
        "businessAuthState",
        "connectionState",
        "authState",
        "companyAuthState",
      ]),
  )(BusinessAuth),
);
