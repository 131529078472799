import type { GlobalState } from "@messenger/global/types";
import type {
  ApiChat,
  ApiUser,
  ApiUserFullInfo,
  ApiUserStatus,
} from "@messenger/api/types";

export function selectUser(
  global: GlobalState,
  userId: string,
): ApiUser | undefined {
  return global.users.byId[userId];
}

export function selectUserStatus(
  global: GlobalState,
  userId: string,
): ApiUserStatus | undefined {
  return global.users.statusesById[userId];
}

export function selectUserFullInfo<T extends GlobalState>(
  global: T,
  userId: string,
): ApiUserFullInfo | undefined {
  // return global.users.fullInfoById[userId];
  const user = selectUser(global, userId);

  return user?.fullInfo;
}

export function selectIsUserBlocked(global: GlobalState, userId: string) {
  const user = selectUserFullInfo(global, userId);

  return user?.isBlocked;
}

// Slow, not to be used in `withGlobal`
export function selectUserByUsername(global: GlobalState, username: string) {
  const usernameLowered = username.toLowerCase();
  return Object.values(global.users.byId).find(
    (user) => user.username.toLowerCase() === usernameLowered,
  );
}

// Slow, not to be used in `withGlobal`
export function selectUserByPhoneNumber(
  global: GlobalState,
  phoneNumber: string,
) {
  const phoneNumberCleaned = phoneNumber.replace(/[^0-9]/g, "");

  return Object.values(global.users.byId).find(
    (user) => user?.phoneNumber === phoneNumberCleaned,
  );
}

export function selectIsUserOrChatContact(
  global: GlobalState,
  userOrChat: ApiUser | ApiChat,
) {
  return global.contactList?.userIds.includes(userOrChat.id);
}
