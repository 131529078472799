import { DEBUG } from "@messenger/config";

const safePlay = (mediaEl: HTMLMediaElement) => {
  mediaEl.play().catch((err) => {
    if (DEBUG) {
      console.warn(err, mediaEl);
    }
  });
};

export default safePlay;
