import Loading from "@messenger/components/auth/Loading";
import React, { type FC, useEffect, memo } from "@messenger/lib/teact/teact";
import { getActions, withGlobal } from "@messenger/global";

import type { GlobalState } from "@messenger/global/types";

import "@messenger/global/actions/initial";
import { pick } from "@messenger/util/iteratees";
import { PLATFORM_ENV } from "@messenger/util/environment";
import windowSize from "@messenger/util/windowSize";
import useHistoryBack from "@messenger/hooks/useHistoryBack";
import useCurrentOrPrev from "@messenger/hooks/useCurrentOrPrev";

import AuthPhoneNumber from "@messenger/components/auth/AuthPhoneNumber";
import AuthCode from "@messenger/components/auth/AuthCode.async";
import AuthPassword from "@messenger/components/auth/AuthPassword.async";
import AuthRegister from "@messenger/components/auth/AuthRegister.async";
import AuthQrCode from "@messenger/components/auth/AuthQrCode";

type OwnProps = {
  isActive: boolean;
};

type StateProps = Pick<GlobalState, "authState">;

const Auth: FC<OwnProps & StateProps> = ({ isActive, authState }) => {
  const { reset, initApi, returnToAuthPhoneNumber, goToAuthQrCode } =
    getActions();

  useEffect(() => {
    if (isActive) {
      reset();
      initApi();
    }
  }, [isActive, reset, initApi]);

  const isMobile = PLATFORM_ENV === "iOS" || PLATFORM_ENV === "Android";

  const handleChangeAuthorizationMethod = () => {
    if (!isMobile) {
      goToAuthQrCode();
    } else {
      returnToAuthPhoneNumber();
    }
  };

  useHistoryBack(
    (!isMobile && authState === "authorizationStateWaitPhoneNumber") ||
      (isMobile && authState === "authorizationStateWaitQrCode"),
    handleChangeAuthorizationMethod,
  );

  // Prevent refresh when rotating device
  useEffect(() => {
    windowSize.disableRefresh();

    return () => {
      windowSize.enableRefresh();
    };
  }, []);

  // For animation purposes
  const renderingAuthState = useCurrentOrPrev(
    authState !== "authorizationStateReady" ? authState : undefined,
    true,
  );

  console.log("DEBUG: auth", renderingAuthState);

  switch (renderingAuthState) {
    case "authorizationStateWaitCode":
      return <AuthCode />;
    case "authorizationStateWaitPassword":
      return <AuthPassword />;
    case "authorizationStateWaitRegistration":
      return <AuthRegister />;
    case "authorizationStateWaitPhoneNumber":
      return <AuthPhoneNumber />;
    case "authorizationStateWaitQrCode":
      return <AuthQrCode />;
    default:
      return <Loading />;
  }
};

export default memo(
  withGlobal<OwnProps>((global): StateProps => pick(global, ["authState"]))(
    Auth,
  ),
);
