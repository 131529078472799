import React, { type FC, memo } from "@messenger/lib/teact/teact";
import { withGlobal } from "@messenger/global";
import { cn } from "@messenger/shared/styles";

import type { LangCode } from "@messenger/types";
import * as styles from "@messenger/components/auth/AuthBrand.module.scss";
import * as authCommonStyles from "@messenger/components/auth/styles.module.scss";

import downloadAndroidPath from "@messenger/assets/download-android.png";
import downloadIosPath from "@messenger/assets/download-ios.png";
import downloadHuaweiPath from "@messenger/assets/download-huawei.png";
import logo from "@messenger/assets/logo.svg";

enum Download {
  Android = "https://play.google.com/store/apps/details?id=com.tawasul.messenger",
  iOS = "https://apps.apple.com/us/app/tawasal-superapp/id1507761438",
  Huawei = "https://appgallery.huawei.com/app/C105867571",
}

type StateProps = {
  language?: LangCode;
};

const AuthBrand: FC<StateProps> = () => {
  return (
    <section className={authCommonStyles.halfPageWrapper}>
      <div className={cn(authCommonStyles.halfPageContainer, styles.container)}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Tawasal" />
          <span className={styles.logoTitle}>TAWASAL</span>
        </div>

        <div>
          <h1 className={styles.title}>Join Tawasal</h1>
          <p className={styles.subtitle}>
            Download Tawasal SuperApp now and stay in touch. Anytime. Anywhere.
          </p>
        </div>

        <div className={styles.downloadButtonsWrapper}>
          <a
            href={Download.iOS}
            target="_blank"
            className={styles.downloadButton}
            rel="noreferrer"
          >
            <img src={downloadIosPath} alt="Download Tawasal on iOS" />
          </a>

          <a
            href={Download.Android}
            target="_blank"
            className={styles.downloadButton}
            rel="noreferrer"
          >
            <img src={downloadAndroidPath} alt="Download Tawasal on Android" />
          </a>

          <a
            href={Download.Huawei}
            target="_blank"
            className={styles.downloadButton}
            rel="noreferrer"
          >
            <img src={downloadHuaweiPath} alt="Download Tawasal on Huawei" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default memo(
  withGlobal((global): StateProps => {
    const {
      settings: {
        byKey: { language },
      },
    } = global;

    return {
      language,
    };
  })(AuthBrand),
);
