import React, { type FC, memo } from "@messenger/lib/teact/teact";
import { Bundles } from "@messenger/util/moduleLoader";

import useModuleLoader from "@messenger/hooks/useModuleLoader";
import Loading from "@messenger/components/ui/Loading";

const AuthPasswordAsync: FC = () => {
  const AuthPassword = useModuleLoader(Bundles.Auth, "AuthPassword");

  return AuthPassword ? <AuthPassword /> : <Loading />;
};

export default memo(AuthPasswordAsync);
