import { useEffect } from "@messenger/lib/teact/teact";

import { ApiMediaFormat } from "@messenger/api/types";

import * as mediaLoader from "@messenger/util/mediaLoader";
import useForceUpdate from "@messenger/hooks/useForceUpdate";

const useMedia = (
  mediaHash: string | false | undefined,
  noLoad = false,
  mediaFormat = ApiMediaFormat.BlobUrl,
  cacheBuster?: number,
  delay?: number | false,
) => {
  const mediaData = mediaHash
    ? mediaLoader.getFromMemory(mediaHash)
    : undefined;
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (!noLoad && mediaHash && !mediaData) {
      const startedAt = Date.now();

      mediaLoader.fetch(mediaHash, mediaFormat).then(() => {
        const spentTime = Date.now() - startedAt;
        if (!delay || spentTime >= delay) {
          forceUpdate();
        } else {
          setTimeout(forceUpdate, delay - spentTime);
        }
      });
    }
  }, [
    noLoad,
    mediaHash,
    mediaData,
    mediaFormat,
    cacheBuster,
    forceUpdate,
    delay,
  ]);

  return mediaData;
};

export default useMedia;
