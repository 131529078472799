// extracted by mini-css-extract-plugin
var _1 = "PhoneCall-module__accept--coRtL";
var _2 = "PhoneCall-module__acceptIcon--bEAq2";
var _3 = "PhoneCall-module__blurred--JcWbe";
var _4 = "PhoneCall-module__buttons--k0UYI";
var _5 = "PhoneCall-module__closeButton--vENJW";
var _6 = "PhoneCall-module__emojiTooltip--uFW9R";
var _7 = "PhoneCall-module__emojis--kVQI_";
var _8 = "PhoneCall-module__emojisBackdrop--laZHH";
var _9 = "PhoneCall-module__fullscreen--FNCAq";
var _a = "PhoneCall-module__header--hyOug";
var _b = "PhoneCall-module__leave--AjL6E";
var _c = "PhoneCall-module__mainVideo--W_NKJ";
var _d = "PhoneCall-module__open--maBSB";
var _e = "PhoneCall-module__root--WVO1G";
var _f = "PhoneCall-module__secondVideo--SAD8c";
var _10 = "PhoneCall-module__singleColumn--csldI";
var _11 = "PhoneCall-module__userInfo--w1cPs";
var _12 = "PhoneCall-module__visible--HrmT7";
export { _1 as "accept", _2 as "acceptIcon", _3 as "blurred", _4 as "buttons", _5 as "closeButton", _6 as "emojiTooltip", _7 as "emojis", _8 as "emojisBackdrop", _9 as "fullscreen", _a as "header", _b as "leave", _c as "mainVideo", _d as "open", _e as "root", _f as "secondVideo", _10 as "singleColumn", _11 as "userInfo", _12 as "visible" }
