import React, { FC } from "@messenger/lib/teact/teact";
import { getActions, withGlobal } from "@messenger/global";

import { ApiChat, ApiUser } from "@messenger/api/types";

import { selectUser } from "@messenger/global/selectors";

type OwnProps = {
  userId?: string;
  username?: string;
  children: React.ReactNode;
};

type StateProps = {
  userOrChat?: ApiUser | ApiChat;
};

const MentionLink: FC<OwnProps & StateProps> = ({
  username,
  userOrChat,
  children,
}) => {
  const { openChat, openChatByUsername } = getActions();

  const handleClick = () => {
    if (userOrChat) {
      openChat({ id: userOrChat.id });
    } else if (username) {
      openChatByUsername({ username: username.substring(1) });
    }
  };

  return (
    <a onClick={handleClick} className="text-entity-link" dir="auto">
      {children}
    </a>
  );
};

export default withGlobal<OwnProps>((global, { userId }): StateProps => {
  return {
    userOrChat: userId ? selectUser(global, userId) : undefined,
  };
})(MentionLink);
