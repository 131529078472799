import { ApiMediaFormat } from "@messenger/api/types";

import * as mediaLoader from "@messenger/util/mediaLoader";

import MonkeyIdle from "@messenger/assets/tgs/monkeys/TwoFactorSetupMonkeyIdle.tgs";
import MonkeyTracking from "@messenger/assets/tgs/monkeys/TwoFactorSetupMonkeyTracking.tgs";
import MonkeyClose from "@messenger/assets/tgs/monkeys/TwoFactorSetupMonkeyClose.tgs";
import MonkeyPeek from "@messenger/assets/tgs/monkeys/TwoFactorSetupMonkeyPeek.tgs";

import FoldersAll from "@messenger/assets/tgs/settings/FoldersAll.tgs";
import FoldersNew from "@messenger/assets/tgs/settings/FoldersNew.tgs";
import DiscussionGroups from "@messenger/assets/tgs/settings/DiscussionGroupsDucks.tgs";
import Lock from "@messenger/assets/tgs/settings/Lock.tgs";
import Congratulations from "@messenger/assets/tgs/settings/Congratulations.tgs";

import CameraFlip from "@messenger/assets/tgs/calls/CameraFlip.tgs";
import HandFilled from "@messenger/assets/tgs/calls/HandFilled.tgs";
import HandOutline from "@messenger/assets/tgs/calls/HandOutline.tgs";
import Speaker from "@messenger/assets/tgs/calls/Speaker.tgs";
import VoiceAllowTalk from "@messenger/assets/tgs/calls/VoiceAllowTalk.tgs";
import VoiceMini from "@messenger/assets/tgs/calls/VoiceMini.tgs";
import VoiceMuted from "@messenger/assets/tgs/calls/VoiceMuted.tgs";
import VoiceOutlined from "@messenger/assets/tgs/calls/VoiceOutlined.tgs";

import Peach from "@messenger/assets/tgs/animatedEmojis/Peach.tgs";
import Eggplant from "@messenger/assets/tgs/animatedEmojis/Eggplant.tgs";
import Cumshot from "@messenger/assets/tgs/animatedEmojis/Cumshot.tgs";

import JoinRequest from "@messenger/assets/tgs/invites/Requests.tgs";
import Invite from "@messenger/assets/tgs/invites/Invite.tgs";

export const ANIMATED_STICKERS_PATHS = {
  MonkeyIdle,
  MonkeyTracking,
  MonkeyClose,
  MonkeyPeek,
  FoldersAll,
  FoldersNew,
  DiscussionGroups,
  Lock,
  CameraFlip,
  HandFilled,
  HandOutline,
  Speaker,
  VoiceAllowTalk,
  VoiceMini,
  VoiceMuted,
  VoiceOutlined,
  Peach,
  Eggplant,
  Cumshot,
  JoinRequest,
  Invite,
  // QrPlane,
  Congratulations,
};

export default function getAnimationData(
  name: keyof typeof ANIMATED_STICKERS_PATHS,
) {
  const path = ANIMATED_STICKERS_PATHS[name].replace(
    window.location.origin,
    "",
  );

  return mediaLoader.fetch(`file${path}`, ApiMediaFormat.Lottie);
}
