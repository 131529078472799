import type { ApiMessage } from "../../../api/types";
import type { LangFn } from "@messenger/hooks/useLang";
import { ApiMessageEntityTypes } from "../../../api/types";

import { getMessageText } from "../../../global/helpers";
import {
  getMessageSummaryDescription,
  getMessageSummaryEmoji,
  getMessageSummaryText,
  TRUNCATED_SUMMARY_LENGTH,
} from "@messenger/global/helpers";
import { getMessageKey } from "@messenger/global/helpers";
import trimText from "../../../util/trimText";
import renderText from "./renderText";
import { renderTextWithEntities, TextPart } from "./renderTextWithEntities";

export function renderMessageText({
  message,
  highlight,
  emojiSize,
  isSimple,
  truncateLength,
  isProtected,
  forcePlayback,
  shouldRenderAsHtml,
  isForMediaViewer,
}: {
  message: ApiMessage;
  highlight?: string;
  emojiSize?: number;
  isSimple?: boolean;
  truncateLength?: number;
  isProtected?: boolean;
  forcePlayback?: boolean;
  shouldRenderAsHtml?: boolean;
  isForMediaViewer?: boolean;
}) {
  const { text, entities } = message.content.text || {};

  if (!text) {
    const contentNotSupportedText = getMessageText(message);
    return contentNotSupportedText
      ? [trimText(contentNotSupportedText.text!, truncateLength)]
      : undefined;
  }

  const messageKey = getMessageKey(message);

  return renderTextWithEntities({
    text: trimText(text, truncateLength),
    entities: entities?.sort((a, b) => a.offset - b.offset),
    highlight,
    emojiSize,
    shouldRenderAsHtml,
    containerId: `${isForMediaViewer ? "mv-" : ""}${messageKey}`,
    isSimple,
    isProtected,
    forcePlayback,
  });
}

// TODO Use Message Summary component instead
export function renderMessageSummary(
  lang: LangFn,
  message: ApiMessage,
  noEmoji = false,
  highlight?: string,
  truncateLength = TRUNCATED_SUMMARY_LENGTH,
): TextPart[] {
  const { entities } = message.content.text || {};

  const hasSpoilers = entities?.some(
    (e) => e.type === ApiMessageEntityTypes.Spoiler,
  );

  if (!hasSpoilers) {
    const text = trimText(
      getMessageSummaryText(lang, message, noEmoji),
      truncateLength,
    );

    if (highlight) {
      return renderText(text, ["emoji", "highlight"], { highlight });
    } else {
      return renderText(text);
    }
  }

  const emoji = !noEmoji && getMessageSummaryEmoji(message);
  const emojiWithSpace = emoji ? `${emoji} ` : "";

  const text = renderMessageText({
    message,
    highlight,
    isSimple: true,
    truncateLength,
  });
  const description = getMessageSummaryDescription(lang, message, text);

  return [
    ...renderText(emojiWithSpace),
    ...(Array.isArray(description) ? description : [description]),
  ].filter(Boolean);
}
