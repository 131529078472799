import type { FC } from "@messenger/lib/teact/teact";
import React, { memo } from "@messenger/lib/teact/teact";
import { Bundles } from "@messenger/util/moduleLoader";

import type { OwnProps } from "@messenger/components/main/LockScreen";

import useModuleLoader from "@messenger/hooks/useModuleLoader";

const LockScreenAsync: FC<OwnProps> = (props) => {
  const { isLocked } = props;
  const LockScreen = useModuleLoader(Bundles.Main, "LockScreen", !isLocked);

  return LockScreen ? <LockScreen {...props} /> : undefined;
};

export default memo(LockScreenAsync);
