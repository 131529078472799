import React, { FC, useCallback } from "@messenger/lib/teact/teact";

import buildClassName from "@messenger/util/buildClassName";

import "@messenger/components/ui/Link.scss";

type OwnProps = {
  children: React.ReactNode;
  className?: string;
  isRtl?: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

const Link: FC<OwnProps> = ({ children, className, isRtl, onClick }) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      onClick!(e);
    },
    [onClick],
  );

  return (
    <a
      href="#"
      className={buildClassName("Link", className)}
      dir={isRtl ? "rtl" : "auto"}
      onClick={onClick ? handleClick : undefined}
    >
      {children}
    </a>
  );
};

export default Link;
