import type { GlobalState } from "@messenger/global/types";
import { NewChatMembersProgress, RightColumnContent } from "@messenger/types";

import {
  getSystemTheme,
  IS_SINGLE_COLUMN_LAYOUT,
} from "@messenger/util/environment";
import {
  selectCurrentMessageList,
  selectIsPollResultsOpen,
} from "@messenger/global/selectors/messages";
import { selectCurrentTextSearch } from "@messenger/global/selectors/localSearch";
import {
  selectCurrentStickerSearch,
  selectCurrentGifSearch,
} from "@messenger/global/selectors/symbols";
import { selectIsStatisticsShown } from "@messenger/global/selectors/statistics";
import { selectCurrentManagement } from "@messenger/global/selectors/management";

export function selectIsMediaViewerOpen(global: GlobalState) {
  const { mediaViewer } = global;
  return Boolean(mediaViewer.messageId || mediaViewer.avatarOwnerId);
}

export function selectRightColumnContentKey<T extends GlobalState>(
  global: T,
  isMobile?: boolean,
) {
  return selectIsPollResultsOpen(global)
    ? RightColumnContent.PollResults
    : // : (!IS_SINGLE_COLUMN_LAYOUT || isMobile) && selectCurrentTextSearch(global)
      !IS_SINGLE_COLUMN_LAYOUT && selectCurrentTextSearch(global)
      ? RightColumnContent.Search
      : selectCurrentManagement(global)
        ? RightColumnContent.Management
        : selectIsStatisticsShown(global)
          ? RightColumnContent.Statistics
          : selectCurrentStickerSearch(global).query !== undefined
            ? RightColumnContent.StickerSearch
            : selectCurrentGifSearch(global).query !== undefined
              ? RightColumnContent.GifSearch
              : global.newChatMembersProgress !== NewChatMembersProgress.Closed
                ? RightColumnContent.AddingMembers
                : global.isChatInfoShown && selectCurrentMessageList(global)
                  ? RightColumnContent.ChatInfo
                  : undefined;
}

export function selectIsRightColumnShown<T extends GlobalState>(
  global: T,
  isMobile?: boolean,
) {
  return selectRightColumnContentKey(global, isMobile) !== undefined;
}

export function selectTheme(global: GlobalState) {
  const { theme, shouldUseSystemTheme } = global.settings.byKey;

  return shouldUseSystemTheme ? getSystemTheme() : theme;
}
