import React, { FC } from "@messenger/lib/teact/teact";

import Button from "@messenger/components/ui/Button";

import appInactivePath from "@messenger/assets/app-inactive.png";
import "@messenger/components/main/AppInactive.scss";

const AppInactive: FC = () => {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div id="AppInactive">
      <div className="content">
        <img src={appInactivePath} alt="" />
        <h3 className="title">Such error, many tabs</h3>
        <div className="description">
          Tawasal supports only one active tab with the app.
          <br />
          Please reload this page to continue using this tab or close it.
        </div>
        <div className="actions">
          <Button isText ripple onClick={handleReload}>
            Reload app
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AppInactive;
