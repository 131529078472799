import { addActionHandler } from "@messenger/global";
import { callApi } from "@messenger/api/gramjs";
import { Api } from "@messenger/lib/gramjs";
import { storeSession } from "@@/src/app/util/sessions";

addActionHandler("storeEmployerSession", async (global, actions, payload) => {  
  const isAuthenticated = await callApi("checkAuth");
  const self = await callApi("getSelfUser");
  const sessionData = await callApi("getSelfSessionData");
  if (isAuthenticated && self) {
    storeSession(sessionData, String(self.id))
    return ({
      ...global,
      authState: "authorizationStateReady",
    })
  }
})

addActionHandler("exportCompanyInvite", async (global, actions, payload) => {
  const { company } = payload;

  const result = await callApi("exportCompanyInvite", { company });

  if (result) {
    const urlParts = (result as Api.ExportedCompanySignUpInvite)?.link.split(
      "/",
    );
    let hash = urlParts[urlParts.length - 1];
    let formattedLink = `${window.location.origin}/business/joincompany/${hash}`;
    await navigator.clipboard.writeText(formattedLink);
    actions.showNotification({ message: "Link copied to clipboard123" });
  }
});

addActionHandler("updateCompanyPhoto", async (global, actions, payload) => {
  const { photo } = payload;

  const result = await callApi("updateCompanyPhoto", { photo });
  const companyPhotoBlob = URL.createObjectURL(photo);
  if (result) {
    actions.createAuthCompany({
      state: "companyWelcomeState",
      company: {
        ...global.company,
        companyPhotoBlob,
      }
    })
  }
});

addActionHandler("checkCompanyInvite", async (global, actions, payload) => {
  const { companyInvite } = payload;
  const result = await callApi("checkCompanyInvite", { invite: companyInvite });
});

addActionHandler("getCompany", async (global, actions, payload) => {
  const { company } = payload;
  const result = await callApi("getCompany", { company });
  if (result) {
    return {
      ...global,
      company: result,
    };
  }
});

addActionHandler("createAuthCompany", async (global, actions, payload) => {
  const { state, company } = payload;
  if (state === "companyCreatedState") {
    void callApi("provideAuthCompanyRegistration");
  }
  return {
    ...global,
    companyAuthState: state,
    company: company ?? global.company,
  }
})