import React, { FC, useCallback } from "@messenger/lib/teact/teact";
import { getActions } from "@messenger/global";

import buildClassName from "@messenger/util/buildClassName";

import Link from "@messenger/components/ui/Link";

type OwnProps = {
  className?: string;
  chatId?: string;
  children: React.ReactNode;
};

const ChatLink: FC<OwnProps> = ({ className, chatId, children }) => {
  const { openChat } = getActions();

  const handleClick = useCallback(() => {
    if (chatId) {
      openChat({ id: chatId });
    }
  }, [chatId, openChat]);

  if (!chatId) {
    return children;
  }

  return (
    <Link
      className={buildClassName("ChatLink", className)}
      onClick={handleClick}
    >
      {children}
    </Link>
  );
};

export default ChatLink;
