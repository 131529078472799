import AuthBrand from "@messenger/components/auth/AuthBrand";
import { cn } from "@messenger/shared/styles";
import React, { type FC, memo } from "@messenger/lib/teact/teact";
import { withGlobal } from "@messenger/global";

import type { GlobalState } from "@messenger/global/types";

import LoadingSpinner from "@messenger/components/ui/Loading";

import * as authCommonStyles from "@messenger/components/auth/styles.module.scss";
import * as styles from "@messenger/components/auth/AuthQrCode.module.scss";

type StateProps = Pick<GlobalState, "connectionState" | "authState">;

const Loading: FC<StateProps> = () => {
  return (
    <div className={authCommonStyles.page}>
      <AuthBrand />

      <section className={authCommonStyles.halfPageWrapper}>
        <div
          className={cn(authCommonStyles.halfPageContainer, styles.container)}
        >
          <LoadingSpinner />
        </div>
      </section>
    </div>
  );
};

export default memo(
  withGlobal((global): StateProps => {
    const { connectionState, authState } = global;

    return {
      connectionState,
      authState,
    } satisfies StateProps;
  })(Loading),
);
