// biome-ignore lint/style/useImportType: <explanation>
import React from "@messenger/lib/teact/teact";
import { type FC, memo, useCallback } from "@messenger/lib/teact/teact";
import { getActions } from "@messenger/global";
import convertPunycode from "@messenger/lib/punycode";

import { DEBUG, RE_TW_LINK, RE_TWL_LINK } from "@messenger/config";
import buildClassName from "@messenger/util/buildClassName";
import { ensureProtocol } from "@messenger/util/ensureProtocol";

type OwnProps = {
  url?: string;
  text: string;
  className?: string;
  children?: React.ReactNode;
  withNormalWordBreak?: boolean;
  isRtl?: boolean;
};

const SafeLink: FC<OwnProps> = ({
  url,
  text,
  className,
  children,
  isRtl,
  withNormalWordBreak,
}) => {
  const { toggleSafeLinkModal, openTelegramLink } = getActions();

  const content = children || text;
  const isNotSafe = url !== content;

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (
        e.ctrlKey ||
        e.altKey ||
        e.shiftKey ||
        e.metaKey ||
        !url ||
        (!url.match(RE_TWL_LINK) && !url.match(RE_TW_LINK))
      ) {
        if (isNotSafe) {
          toggleSafeLinkModal({ url });

          e.preventDefault();
          return false;
        }

        return true;
      }

      e.preventDefault();
      openTelegramLink({ url });

      return false;
    },
    [isNotSafe, openTelegramLink, toggleSafeLinkModal, url],
  );

  if (!url) {
    return undefined;
  }

  const classNames = buildClassName(
    className || "text-entity-link",
    !withNormalWordBreak && "word-break-all",
    text.length > 50 && "long-word-break-all",
  );

  return (
    <a
      href={ensureProtocol(url)}
      title={getDomain(url)}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames}
      onClick={handleClick}
      dir={isRtl ? "rtl" : "auto"}
    >
      {content}
    </a>
  );
};

function getDomain(url?: string) {
  if (!url) {
    return undefined;
  }

  const href = ensureProtocol(url);
  if (!href) {
    return undefined;
  }

  try {
    let decodedHref = decodeURI(href);

    const match = decodedHref.match(/^https?:\/\/([^/:?#]+)(?:[/:?#]|$)/i);
    if (!match) {
      return undefined;
    }
    const domain = match[1];
    decodedHref = decodedHref.replace(domain, convertPunycode(domain));

    return decodedHref;
  } catch (error) {
    if (DEBUG) {
      console.error("SafeLink.getDecodedUrl error ", url, error);
    }
  }

  return undefined;
}

export default memo(SafeLink);
