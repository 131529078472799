import React, { FC, useCallback } from "@messenger/lib/teact/teact";
import { getActions } from "@messenger/global";

import { ApiMessage } from "@messenger/api/types";

import buildClassName from "@messenger/util/buildClassName";

import Link from "@messenger/components/ui/Link";

type OwnProps = {
  className?: string;
  message?: ApiMessage;
  children: React.ReactNode;
};

const MessageLink: FC<OwnProps> = ({ className, message, children }) => {
  const { focusMessage } = getActions();

  const handleMessageClick = useCallback((): void => {
    if (message) {
      focusMessage({ chatId: message.chatId, messageId: message.id });
    }
  }, [focusMessage, message]);

  if (!message) {
    return children;
  }

  return (
    <Link
      className={buildClassName("MessageLink", className)}
      onClick={handleMessageClick}
    >
      {children}
    </Link>
  );
};

export default MessageLink;
