import React from "@messenger/lib/teact/teact";

import { EDITABLE_INPUT_ID } from "@messenger/config";
import { IS_IOS } from "@messenger/util/environment";

export function preventMessageInputBlur(
  e: React.MouseEvent<HTMLElement>,
  withBubbling = false,
) {
  if (
    IS_IOS ||
    !document.activeElement ||
    document.activeElement.id !== EDITABLE_INPUT_ID ||
    (!withBubbling && e.target !== e.currentTarget)
  ) {
    return;
  }

  e.preventDefault();
}

export function preventMessageInputBlurWithBubbling(
  e: React.MouseEvent<HTMLElement>,
) {
  preventMessageInputBlur(e, true);
}
