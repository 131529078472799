import { callApi } from "@messenger/api/gramjs";

import { getGlobal } from "..";
import bigInt from "big-integer";

export const REGULAR_USER_LOCAL_KEY = "regular_user_auth";
export const MULTI_USERS_MAP = "multi_users_map";

export function uint8ArrayToBase64(uint8Array: any) {
  return btoa(String.fromCharCode.apply(null, uint8Array));
}

export function base64ToUint8Array(base64String: string) {
  const binaryString = atob(base64String);
  const uint8Array = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }
  return uint8Array;
}

export async function getSessionForMiniapp() {
  const result = await callApi("exportRegularAuthorization");
  const base64Bytes = uint8ArrayToBase64(result?.bytes);
  return base64Bytes;
}

export async function saveCurrentSession() {
  const self = await callApi("getSelfUser");
  const { currentUserId } = getGlobal();

  const result = await callApi("exportRegularAuthorization");

  const base64Bytes = uint8ArrayToBase64(result?.bytes);
  if (base64Bytes && result && currentUserId) {
    const prevUsersMap = localStorage.getItem(MULTI_USERS_MAP);
    const prevUsers = prevUsersMap ? JSON.parse(prevUsersMap) : {};

    localStorage.setItem(
      MULTI_USERS_MAP,
      JSON.stringify({
        ...prevUsers,
        [currentUserId]: JSON.stringify({
          firstName: self?.firstName || "",
          lastName: self?.lastName || "",
          username: self?.username || "",
          business: Boolean(self?.business),
          phone: self?.phone || "",
        }),
      }),
    );

    const prevState = localStorage.getItem(REGULAR_USER_LOCAL_KEY);
    const prev = prevState ? JSON.parse(prevState) : {};

    const info = JSON.stringify({
      ...prev,
      [currentUserId]: base64Bytes,
    });
    localStorage.setItem(REGULAR_USER_LOCAL_KEY, info);
  }
}
