import React, { type FC, memo } from "@messenger/lib/teact/teact";

import Spinner from "@messenger/components/ui/Spinner";
import buildClassName from "@messenger/util/buildClassName";

import "@messenger/components/ui/Loading.scss";

type OwnProps = {
  color?: "blue" | "white" | "black" | "yellow";
  backgroundColor?: "light" | "dark";
  onClick?: NoneToVoidFunction;
};

const Loading: FC<OwnProps> = ({
  color = "yellow",
  backgroundColor,
  onClick,
}) => {
  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
    <div
      className={buildClassName("Loading", onClick && "interactive")}
      onClick={onClick}
    >
      <Spinner color={color} backgroundColor={backgroundColor} />
    </div>
  );
};

export default memo(Loading);
